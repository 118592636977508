import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c(VToolbarTitle, [_c('h5', [_vm._v(_vm._s(_vm.$t('accountList')))])]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = true;
      }
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('action.addAccount')) + "\n    ")])], 1), _vm._v(" "), _c('ContentArea', [_c('Input', {
    staticClass: "mb-6",
    attrs: {
      "label": _vm.$t('keyword'),
      "clearable": "",
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": _vm.$t('placeholder.keyword.accounts')
    },
    model: {
      value: _vm.filterOptions.keywordText,
      callback: function callback($$v) {
        _vm.$set(_vm.filterOptions, "keywordText", $$v);
      },
      expression: "filterOptions.keywordText"
    }
  }), _vm._v(" "), _c('CustomizableHeaderTable', {
    attrs: {
      "sort-desc": ['created_at'],
      "headers": [{
        text: '会社名',
        value: 'name',
        multiLine: true,
        hideable: false
      }, {
        text: '会社住所',
        value: 'shipping_addresses',
        multiLine: true,
        empty: _vm.$t('notSet')
      }, {
        text: 'Webサイト',
        value: 'site_url',
        multiLine: true,
        empty: _vm.$t('notSet')
      }, {
        text: 'CRM',
        value: 'crm',
        empty: _vm.$t('notLink'),
        ellipsis: false
      }, {
        text: '保有コンタクト数',
        value: 'contact_count',
        align: 'center',
        transform: function transform(val) {
          return _vm.$t('people', [val.toLocaleString()]);
        }
      }],
      "default-headers": ['会社名', '会社住所', 'Webサイト', '保有コンタクト数'],
      "items": _vm.accounts,
      "item-key": "id",
      "loading": _vm.loading,
      "options": _vm.tableOptions,
      "server-items-length": _vm.totalItems,
      "disable-sort": true
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      },
      "click:row": function clickRow(item, _, event) {
        return _vm.onClickItem(item, event);
      },
      "auxclick:row": function auxclickRow(event, row) {
        return _vm.onClickItem(row.item, event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.site_url",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.site_url ? _c('LinkText', {
          attrs: {
            "text": item.site_url,
            "to": item.site_url
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.shipping_addresses",
      fn: function fn(_ref2) {
        var _item$shipping_addres;
        var item = _ref2.item;
        return [(_item$shipping_addres = item.shipping_addresses) !== null && _item$shipping_addres !== void 0 && _item$shipping_addres.length ? _c('FloatingTooltip', {
          attrs: {
            "top": "",
            "hoverable": "",
            "max-width": "400px",
            "open-delay": "500"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var _ref4, _item$shipping_addres2, _item$shipping_addres3;
              var on = _ref3.on,
                attrs = _ref3.attrs;
              return [_c('div', _vm._g(_vm._b({
                staticClass: "tw-flex"
              }, 'div', attrs, false), on), [_c('AddressLabel', {
                staticClass: "tw-pointer-events-none",
                attrs: {
                  "small": "",
                  "detail": "",
                  "rows": "",
                  "max-width": "200",
                  "alert": false,
                  "address": (_ref4 = (_item$shipping_addres2 = item.shipping_addresses.find(function (it) {
                    var _item$primary_shippin;
                    return it.id === ((_item$primary_shippin = item.primary_shipping_address) === null || _item$primary_shippin === void 0 ? void 0 : _item$primary_shippin.id);
                  })) !== null && _item$shipping_addres2 !== void 0 ? _item$shipping_addres2 : item.shipping_addresses[0]) !== null && _ref4 !== void 0 ? _ref4 : {}
                },
                scopedSlots: _vm._u([((_item$shipping_addres3 = item.shipping_addresses) === null || _item$shipping_addres3 === void 0 ? void 0 : _item$shipping_addres3.length) > 1 ? {
                  key: "label.prepend",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "tw-ml-1 tw-text-body-2 tw-text-secondary"
                    }, [_vm._v("\n                    " + _vm._s('+' + _vm.$t('case', [item.shipping_addresses.length - 1])) + "\n                  ")])];
                  },
                  proxy: true
                } : null], null, true)
              })], 1)];
            }
          }], null, true)
        }, [_vm._v(" "), _vm._l(item.shipping_addresses, function (address, index) {
          return _c('AddressLabel', {
            key: index,
            class: {
              'tw-mt-1': index > 0
            },
            attrs: {
              "detail": "",
              "rows": "",
              "small": "",
              "address": address,
              "color": "white"
            }
          });
        })], 2) : _vm._e()];
      }
    }, {
      key: "item.crm",
      fn: function fn(_ref5) {
        var _vm$$datetime;
        var item = _ref5.item;
        return [item !== null && item !== void 0 && item.crm_id ? [item !== null && item !== void 0 && item.get_crm_display ? _c('LinkText', {
          attrs: {
            "text": item === null || item === void 0 ? void 0 : item.get_crm_display,
            "to": item === null || item === void 0 ? void 0 : item.crm_url
          }
        }) : _vm._e(), _vm._v(" "), _c('div', {
          staticClass: "text-caption secondary-text--text"
        }, [_vm._v("\n            " + _vm._s(_vm.$t('lastSyncAt', [(_vm$$datetime = _vm.$datetime(item.last_synced_at)) !== null && _vm$$datetime !== void 0 ? _vm$$datetime : _vm.$t('notSync')])) + "\n          ")])] : _vm._e()];
      }
    }])
  })], 1), _vm._v(" "), _c('EditAccountDialog', {
    attrs: {
      "show": _vm.dialog
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.dialog = $event;
      },
      "create": function create($event) {
        _vm.filterOptions = {};
        _vm.tableOptions.page = 1;
        _vm.fetchAccounts;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };